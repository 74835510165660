//import { Intercom } from '@supy-io/ngx-intercom';
//import { ErrorService } from '@library';
import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Authentication, Environment } from '@library/base';
import jwt_decode from 'jwt-decode';
import { CookieService } from 'ngx-cookie-service';
import { Subscription, interval } from 'rxjs';
import { ApiBaseRoutes } from '../api-base-routes';

// Should not be injectable, see table/periodic table for example
@Injectable({
    providedIn: 'root'
})
export class AuthenticationBaseService {

    private _Authentication!: Authentication;  // Must be set 
    private _REFRESH_TOKEN_EVERY_X_MINUTES_CHECK = 1; // refresh token every minute
    private _cookieRefreshSubscription!: Subscription;

    constructor(private _CookieService: CookieService,
                private _Environment: Environment) {

        ApiBaseRoutes.Application.LogOut.URL = _Environment.Application.LogoutURL!;
        
        if (this.isAuthTokenAvailable) {
            this.SetupAutomaticSettingQuery();
            this.StoreAuth();
        }
    }

    private StoreAuth(): void {
        if (this.CookieJWTAuth != null && this.CookieJWTAuth != undefined) {
            this._Authentication = this.ReturnDecodedJWT(this.CookieJWTAuth);
        }
    }

    public ReturnDecodedJWT(JsonWebToken: any): any {
        return (JsonWebToken ? jwt_decode(JsonWebToken) : null);
    }


    public get authJWT() {
        return this.GetCookie(this.CookieNameAuth);
    }

    public get loggedInProfileID() {
        return this._Authentication?.ProfileID;
    }

    protected get CookieNameAuth() {
        return this._Environment.Authentication?.PrimaryToken!;
    }

    SetAuthenticationOverride(jwt: string) {
        this._Authentication = this.ReturnDecodedJWT(jwt);;
    }

    protected get CookieJWTAuth() {
        return this.GetCookie(this.CookieNameAuth);
    }

    public get FirstName(): string {
        return this._Authentication.FirstName;
    }

    public get LastName(): string {
        return this._Authentication.LastName;
    }

    public get EmailAddress(): string {
        return this._Authentication.EmailAddress;
    }

    public get ElevioUserHash(): string {
        return this._Authentication.ElevioUserHash;
    }

    public get AuthIssueTime(): number {
        return this._Authentication.iat;
    }

    public get AuthExpiryTime(): number {
        return this._Authentication.exp;
    }

    public get isImpersonating(): boolean {
        return this._Authentication?.Impersonating;
    }

    public get isAuthTokenAvailable(): boolean {
        if (this.authJWT) {
            return true;
        } else {
            return false;
        }
    }

    public get returnURL(): string {
        return this._Authentication.ReturnUrl;
    }

    public get authIntercomCompanyId(): string {
        return this._Authentication.IntercomCompanyID;
    }

    public get authIntercomUserId(): string {
        return this._Authentication.IntercomUserID;
    }

    public Signout() {
        ApiBaseRoutes.Application.LogOut.Call().subscribe(_ => this.NavigateToLogin());
    }

    public NavigateToLogin(): void {
        //window.location.replace(this._Environment.Application.LoginURL);
        //this._Intercom.shutdown(); // clear intercom cookie using ng-intercom npm package
    }

    CancelCookieRefresh(): void {
        this._cookieRefreshSubscription?.unsubscribe();
    }

    DeletePrimaryCookie(): void {
        this._CookieService.delete(this.CookieNameAuth);
    }

    private SetupAutomaticSettingQuery() {
        this._cookieRefreshSubscription = interval(this._REFRESH_TOKEN_EVERY_X_MINUTES_CHECK * 1000 * 60).subscribe(_ => {
            if (this.CheckIfRefreshTokenShouldBeCalled()){
                this.CallApiRefreshToken();
            }
        });
    }

    private CheckIfRefreshTokenShouldBeCalled(): boolean {
        const oneHourInMinutes = 60;
        const oneMinuteInSeconds = 60;
        const oneSecondInMs = 1000;
        const currentTimeInSeconds = Math.trunc(+new Date()/oneSecondInMs);
        const tokenTimeLeftInSeconds = Math.trunc((this.AuthExpiryTime - currentTimeInSeconds));
        const expiryTimeoutInSeconds = Math.trunc((this.AuthExpiryTime - this.AuthIssueTime));

        if(tokenTimeLeftInSeconds < 0){
            return false; // Expired
        }


        //Is the new expiry date more than 1 hour in the future? And if so, would updating the expiry date add more than 1 hour to it?
        const expiryDate = new Date((currentTimeInSeconds + expiryTimeoutInSeconds) * oneSecondInMs);
        if(tokenTimeLeftInSeconds > oneHourInMinutes*oneMinuteInSeconds) {
        if(expiryDate > new Date((this.AuthExpiryTime + oneHourInMinutes*oneMinuteInSeconds)*oneSecondInMs)){
            return true;
        } else {
            return false;
        }
        }


        //Is the new expiry date more than 1 minute in the future? If so, allow it
        if (expiryDate > new Date((this.AuthExpiryTime + oneMinuteInSeconds)*oneSecondInMs)) {
            return true;
        }

        return false; //Can't refresh right now. Try again later.
    }

    public CallApiRefreshToken(): void {
        ApiBaseRoutes.Authentication.Refresh.Call().subscribe(data => { 
            this.SetCookie(data);
            this.StoreAuth();
        });
    }

    protected GetCookie(name: string): string {
        return this._CookieService.get(name);
    }

    public SetCookie(JWT: string) {
        let cookieOptions = {
            path: '/',
            expires: new Date(this.ReturnDecodedJWT(JWT).exp * 1000) // the JWT expiry is in seconds, Date takes milliseconds
        }
        this._CookieService.set(this.CookieNameAuth, JWT, cookieOptions);
    }

    public ApiError(httpErrorResponse: HttpErrorResponse) {
        // console.log(httpErrorResponse);
        //this._ErrorService.APIError(httpErrorResponse);
    }
}
